import React, { Component } from 'react';
import Sprache from './sprache/sprache';
import Gesicht from './gesicht/gesicht';
import Tiere from './tiere/tiere';
import Videos from './videos/videos';

class Content extends Component {

  dyn_content(){
    if (this.props.id === 1){
      return(
        <div className='frame video fadeIn05'>
          <video autoPlay src={this.props.server+'/_videos/intro.mp4'} type="video/mp4"/>
        </div>
      )
    }
    if (this.props.id === 2){
      return(<Sprache
        deeplKey = {this.props.deeplKey}
        server = {this.props.server}
        setInactive = {this.props.setInactive}
        />)
    }
    if (this.props.id === 3){
      return(<Gesicht
        server = {this.props.server}
        setInactive = {this.props.setInactive}
        />)
    }
    if (this.props.id === 4){
      return(<Tiere
        server = {this.props.server}
        setInactive = {this.props.setInactive}
        />)
    }
    if (this.props.id === 5){
      return(<Videos
        server = {this.props.server}
        setInactive = {this.props.setInactive}
        />)
    }

    if (this.props.id === 6){
      return( <div className="frame impressum fadeIn05" style={{backgroundImage:`url(./img/impressum.png)`}} ></div>)
    }

    return('')
  }

  render() {
    return (
      <div>{this.dyn_content()}</div>
    );
  }

}

export default Content;
