import React, {} from 'react';
import Button from './button';
import './menue.css';

function Menue(props) {

    const buttons = props.buttons;
    const onClick = props.onClick;

    return (
      <div className="buttons">
      {buttons.map(button =>
        <Button
          key={button.id}
          onClick={onClick}
          button={button}
        />
      )}

      </div>
    );

}

export default Menue;
