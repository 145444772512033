import styled, {css, keyframes } from 'styled-components';

const rotate = keyframes`
  { 0%   { transform: rotate(180deg); }
    50%  { transform: rotate(180deg); }
    100%  { transform: rotate(360deg); }
  }`

const animation = props =>
css`
  ${rotate} 5s 1 linear;
  animation-fill-mode: forwards;
`

const Rotator2 = styled.div`
transform-origin: 100% 50%;
position: absolute;
top: 0;
left: 0;
background-color: #000;
height: 100%;
width: 100%;
animation: ${animation};
`

export default Rotator2
