import React, {Fragment, useState, useEffect } from 'react'


const InputKey = (props) => {
  const appState = props.appState;
  const setToTranslate = props.setToTranslate;
  const [input, setInput] = useState('');
  const [shiftState, setShiftState] = useState(false);

  const buttons_row1 = ['q','w','e','r','t','z','u','i','o','p','ü','⌫'];
  const buttons_row2 = ['a','s','d','f','g','h','j','k','l','ö','ä'];
  const buttons_row3 = ['↥','y','x','c','v','b','n','m','__',',','.','?'];

  const buttons_row1_shift = ['Q','W','E','R','T','Z','U','I','O','P','Ü','⌫'];
  const buttons_row2_shift = ['A','S','D','F','G','H','J','K','L','Ö','Ä'];
  const buttons_row3_shift = ['↥','Y','X','C','V','B','N','M','__',',','.','?'];

  const keyDown =  (key) =>{
    if(key === '✓'){setToTranslate(input); setInput('');}
    else if(key === '↥' && shiftState) {setShiftState(false)}
    else if(key === '↥') {setShiftState(true)}
    else if(key === '⌫') {setInput(input.substr(0, input.length-1))}
    else if(key === '__') {setInput(input+' ')}
    else {setInput(input+key); if(shiftState) {setShiftState(false)}};
  }

  const keyboard = <div className='keyboard'>
    <div className="row1">
      {!shiftState ? buttons_row1.map(button =><div key={button} onClick={() => keyDown(button)} className='keyboard_btn'>{button}</div>)
      : buttons_row1_shift.map(button =><div key={button} onClick={() => keyDown(button)} className='keyboard_btn'>{button}</div>)}
    </div>
    <div className="row2">
      {!shiftState ? buttons_row2.map(button =><div key={button} onClick={() => keyDown(button)} className='keyboard_btn'>{button}</div>)
      : buttons_row2_shift.map(button =><div key={button} onClick={() => keyDown(button)} className='keyboard_btn'>{button}</div>)}
    </div>
    <div className="row3">
      {!shiftState ? buttons_row3.map(button =><div key={button} onClick={() => keyDown(button)} className={'keyboard_btn ' + ((button === '__')? 'stretch':'')}>{button}</div>)
      : buttons_row3_shift.map(button =><div key={button} onClick={() => keyDown(button)} className={'keyboard_btn ' + ((button === '↥')? 'active ':'' + ((button === '__')? 'stretch ':''))}>{button}</div>)}
    </div>
  </div>

  useEffect(()=>{
    if (appState !== 'input_key'){
      setInput('');
    }
  },[appState])

  const onClickKey = () => {
    props.setAppState('input_key');
  }

  const onClickOK = () => {
    const lng_list = [...props.lng_list];
    const selection = Math.floor(Math.random()*lng_list.length)
    props.setLng(lng_list[selection]);
    setToTranslate(input);
    setInput('');
  }

  const key_btn = <div onClick={onClickKey} style={{backgroundImage:`url(img/${(appState === 'input_key') ? 'abc_black' : 'abc_white'}.svg)`}} className={(appState === 'input_key') ? 'button_round key_btn active' : ' button_round key_btn'}><div className='line'></div></div>
  const ok_btn = <div onClick={onClickOK} style={{backgroundImage:`url(img/ok.svg)`}} className='ok_btn'><span className='ok_btn_text'>Übersetzung und<br/>Rückübersetzung starten</span></div>

  return (
    <Fragment>
      {key_btn}
      {(appState === 'input_key' && input.length > 0) ? ok_btn:''}
      {(appState === 'input_key') ? keyboard : ''}
      {(input.length > 0)? <p>{input}</p> : ''}
    </Fragment>
  )
}

export default InputKey
