import styled, {css, keyframes } from 'styled-components';

const rotate = keyframes`
  { 0%   { transform: rotate(180deg); }
    50%  { transform: rotate(360deg); }
    100%  { transform: rotate(360deg); }
  }`

const animation = props =>
css`
  ${rotate} 5s 1 linear;
  animation-fill-mode: forwards;
`

const Rotator = styled.div`
transform-origin: 0% 50%;
position: absolute;
top: -1px;
left: -1px;
background-color: #000;
height: 92px;
width: 92px;
animation: ${animation};
`

export default Rotator
