import React from 'react';

function Button(props) {
  const icon = '/img/menue'+props.button.id+'.svg';
  let classes = 'button ';
  classes += (props.button.active) ? 'active ' : '';
  classes += (props.button.size === 'small') ? 'small ' : '';
  return (
    <div onClick={() => props.onClick(props.button)} style={{backgroundImage:`url(${icon})`}} className={classes}><div className='line'></div></div>
  );

}

export default Button;
