import styled, {css, keyframes } from 'styled-components';


const showLevel = (props) => keyframes`
  { 0%   { transform: scaleY(0); }
    60%  { transform: scaleY(${props.key1}); }
    80%  { transform: scaleY(${props.key2}); }
    80%  { transform: scaleY(${props.key5}); }
    100%  { transform: scaleY(0.9); }
  }`

const animation = () =>
css`
  ${showLevel} 3s 1;
  animation-fill-mode: forwards;
`

const Bar = styled.div`
transform-origin: 50% 100%;
position: absolute;
bottom: 0;
left: 0;
background-color: #fff;
font-size: 20px;
height: ${props => props.height}};
width: 100%;
animation: ${animation};
`

export default Bar
