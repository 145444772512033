import React, {useEffect, useState} from 'react';

const SpracheList = (props) => {
  const appState = props.appState;
  const setAppState = props.setAppState;
  const setLng = props.setLng;
  const setToTranslate = props.setToTranslate;
  const setStoredStringForTranslation = props.setStoredStringForTranslation;
  const [listing, setListing] = useState([]);
  const [remapedListing, setRemapedListing] = useState([]);
  const [shown, setShown] = useState('');
  const [sortMode, setSortMode] = useState('time');
  const [page, setPage] = useState(0);
  const setShow = props.setShow;
  const maxListLength = 25;
  const server = props.server;
  const [likeAble, setLikeAble] = useState(true);

  const AddLike = async () => {
    setAppState('like');
    try {
      console.log('like');
      const response = await fetch(`${server}/php/addlike.php`, {
        method: 'post',
        headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json'
               },
        body: JSON.stringify({shown:shown})
      });
      const json = await response.text();
      console.log('antwort_like: ',json);
      setLikeAble(false);
      setAppState('show');
    } catch (error) {
      setAppState('show');
      console.log(error);
    }
  };

  useEffect(() => {
    async function myloader() {
      try {
        console.log('lade_liste');
        const response = await fetch(`${server}/php/loadinfo.php`, {
          method: 'get',
          headers: {
                   'Accept': 'application/json',
                   'Content-Type': 'application/json'
                   }
        });
        const json = await response.json();
        console.log('antwort: ',json);
        setListing(json);
        //remap();
      } catch (error) {
        console.log(error);
      }
    }

    if(appState === 'wait' || appState === 'pre' || appState === 'show' ){myloader()}
    if(appState === 'save'){setSortMode('time')}
  }
  ,[appState, server])

  useEffect(() => {
    if(sortMode === 'like'){
      listing.sort(function (a, b) {
        return b.like - a.like;
      });
    }
    if(sortMode === 'time'){
      listing.sort(function (a, b) {
        return b.id - a.id;
      });
    }
    let temp_array = [];
    for (var i = 0 + ((page)*(maxListLength)); (i < listing.length && i <= ((page+1)*(maxListLength)-1)); i++) {
      temp_array.push(listing[i]);
    }
    setRemapedListing(temp_array.reverse());
  },[listing, sortMode, page])

  useEffect(() => {
    setPage(0);
  },[sortMode])

  function cut_output(input) {
    if (input.length > 45){
      return(input.substr(0, 45)+'...')
    }else {
      return(input);
    }
  }

  const onClickShow = (id) => {
    setAppState('show');
    setToTranslate('');
    setShown(id);
    let index = listing.findIndex(x => x.id === id);
    setShow(<div className="show"><p className='to_translate'>{listing[index].toTranslate}</p><p className='translation'>/<span className='lng'>{listing[index].lng}</span>{'/ '}{listing[index].translationBack}</p></div>);
    setLng(listing[index].lng);
    setStoredStringForTranslation(listing[index].toTranslate);
    setLikeAble(true);
  }

  const onClickLike = (index) => {
    AddLike();
  }

  const onClickSortLike = () => {
    setSortMode('like');
  }

  const onClickSortTime = () => {
    setSortMode('time');
  }

  const onClickPagePlus = () => {
    if (page === Math.floor(listing.length/maxListLength)){setPage(Math.floor(listing.length/maxListLength));
    }else{setPage(page+1);}
  }
  const onClickPageDown = () => {
    if (page === 0){ setPage(0);
    }else{ setPage(page-1);}
  }

  useEffect(() =>{
    console.log('page: ',page );
    let temp_array = [];
    for (var i = 0 + ((page)*(maxListLength)); (i < listing.length && i <= ((page+1)*(maxListLength)-1)); i++) {
      temp_array.push(listing[i]);
    }
    setRemapedListing(temp_array.reverse());
  },[page, listing])

  const listing_output = <div>
    {(listing.length < 1) ? 'lade' :'' }
    {(page < Math.floor(listing.length/maxListLength)) ? <div className='list_element'>...</div> : ''}
    {remapedListing.map((element) =>
      <div onClick={() => onClickShow(element.id)} key={element.id} className={(element.id === shown && (appState === 'show' || appState === 'like')) ? 'list_element active': 'list_element'}>
        <div className='text'>{cut_output('//' + element.translationBack + '  /' + element.toTranslate)}</div>
        <div className={(element.like > 0) ? 'like' : ''}>{(element.like > 0) ? (element.like) : ''}</div>
        <div className="arrow" style={{backgroundImage:`url(img/arrow_left.svg)`}}></div>
      </div>)
    }
    {(page > 0) ? <div className='list_element'>...</div> : ''}
  </div>

  const like_btn = <div onClick={onClickLike} style={{backgroundImage:`url(img/${(appState === 'save') ? 'like_black' : 'like_white'}.svg)`}} className={(appState === 'save') ? 'button_round save_btn active' : ' button_round save_btn'}><div className='line'></div><div className='line2'></div></div>
  const like_btn_empty = <div className={(appState === 'save') ? 'button_round save_btn active' : ' button_round save_btn'}><div className='line'></div><div className='line2'></div></div>
  const sort_btn_time = <div onClick={onClickSortTime} style={{backgroundImage:`url(img/${(sortMode === 'time') ? 'sort_time_black' : 'sort_time_white'}.svg)`}} className={(sortMode === 'time') ? 'button_sort_time active' : ' button_sort_time'}></div>
  const sort_btn_like = <div onClick={onClickSortLike} style={{backgroundImage:`url(img/${(sortMode === 'like') ? 'sort_like_black' : 'sort_like_white'}.svg)`}} className={(sortMode === 'like') ? 'button_sort_like active' : ' button_sort_like'}></div>
  const page_plus_btn = <div onClick={onClickPagePlus} style={{backgroundImage:`url(img/arrow_up.svg)`}} className=' button_page_plus'></div>
  const page_minus_btn = <div onClick={onClickPageDown} style={{backgroundImage:`url(img/arrow_down.svg)`}} className=' button_page_minus'></div>

  return (
    <div>
      {(appState === 'show' && likeAble) ? like_btn : ''}
      {((appState === 'show' || appState === 'like') && !likeAble) ? like_btn_empty : ''}
      {listing_output}
      {sort_btn_like}
      {sort_btn_time}
      {(page < Math.floor(listing.length/maxListLength)) ? page_plus_btn : ''}
      {(page > 0) ? page_minus_btn : ''}
    </div>
  );

}

export default SpracheList;
