import React, { useState, useEffect } from 'react';
import SpracheList from './sprache_list';
import InputVoice from './input_voice';
import InputKey from './input_key';
import Translate from './translate';
import TranslateBack from './translate_back';
import LngButtons from './lng_buttons';
import SaveTranslation from './save_translation';
import './sprache.css';

function Sprache(props) {
  const setInactive = props.setInactive;
  const [appState, setAppState] = useState('pre');
  const [toTranslate, setToTranslate] = useState('');
  const [translation, setTranslation] = useState('');
  const [translationBack, setTranslationBack] = useState('');
  const [lng, setLng] = useState('en');
  const lng_list = ['en','es','fr','it','ja','nl','pl','pt','ru','zh'];
  const [show, setShow] = useState(null);
  const [storedStringForTranslation, setStoredStringForTranslation] = useState('');
  const deeplKey = props.deeplKey;

  useEffect(() => {
    console.log('appState ', appState);
    setInactive(Date.now());
    if(appState === 'input' || appState === 'input_key')
    {
      setToTranslate('');
      setTranslation('');
      setTranslationBack('');
    }
    if(appState === 'pre_translationBack'){
      const timer = setTimeout(() => {
        setAppState('translationBack');
        clearTimeout(timer);
      }, window.translation_wait_time);
      return () => clearTimeout(timer);
    }
  }, [appState, setInactive])

  useEffect(() => {
    if(toTranslate.length > 0)
    {
      setTranslation('');
      setTranslationBack('');
      setAppState('translation');
    }
  }, [lng, toTranslate])

  useEffect(() => {
    if(translation.length > 0)
    {
      setAppState('pre_translationBack');
    }
  }, [translation])

  useEffect(() => {
    if(translationBack.length > 0)
    {
      setAppState('wait');
    }
  }, [translationBack])

  return (
    <div className="sprache">
      <div className="frame input fadeIn05">
        {(appState === 'pre')? <div className="input_start">Kannst Du etwas Sinnvolles sagen oder schreiben, das zu komplex für eine automatisierte Übersetzung ist?</div> : ''}

        <InputVoice
          appState = {appState}
          setToTranslate = {setToTranslate}
          setAppState = {setAppState}
          lng = {lng}
          setLng = {setLng}
          lng_list = {lng_list}
        />
        <InputKey
          appState = {appState}
          setToTranslate = {setToTranslate}
          setAppState = {setAppState}
          lng = {lng}
          setLng = {setLng}
          lng_list = {lng_list}
        />
        {(toTranslate.length > 0 && appState !== 'show') ? <p className="to_translate">{toTranslate}</p> : ''}
        <Translate
            appState = {appState}
            text = {toTranslate}
            setTranslation = {setTranslation}
            lng = {lng}
            deeplKey = {deeplKey}
            server = {props.server}
            translationBack = {translationBack}
          />
        <TranslateBack
          appState = {appState}
          text = {translation}
          setTranslationBack = {setTranslationBack}
          lng = {lng}
          deeplKey = {deeplKey}
          server = {props.server}
        />
        {(show && appState === 'show') ? show : ''}
        <LngButtons
          setToTranslate = {setToTranslate}
          storedStringForTranslation = {storedStringForTranslation}
          toTranslate = {toTranslate}
          appState = {appState}
          lng = {lng}
          setLng = {setLng}
          server = {props.server}
        />
        <SaveTranslation
          appState = {appState}
          setAppState = {setAppState}
          lng = {lng}
          toTranslate = {toTranslate}
          translation = {translation}
          translationBack = {translationBack}
          server = {props.server}
        />
        <div className='links fadeIn05'></div>
      </div>
      <div className={(appState === "pre" || appState === "input_key" || appState === "input") ? 'frame list fadeIn1' : 'frame list fadeIn1 off'}>
        <SpracheList
          setToTranslate = {setToTranslate}
          setStoredStringForTranslation = {setStoredStringForTranslation}
          setShow = {setShow}
          setLng = {setLng}
          setAppState = {setAppState}
          appState = {appState}
          server = {props.server}
        />
      </div>
    </div>
  );
}

export default Sprache;
