import React,{ useState, useEffect} from 'react';


function TranslateBack(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [translations, setTranslations] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const lng = props.lng;
  const text = props.text;
  const appState = props.appState;
  const setTranslationBack = props.setTranslationBack;
  const deeplKey = props.deeplKey;

  useEffect(() => {
    if(translations.length > 0){
      setTranslationBack(translations);
    }
  },[translations, setTranslationBack])

  useEffect(() => {
    if(text.length > 1 && appState === 'translationBack'){
      setIsLoaded(false);
      setTranslations('');
      fetch(`https://api.deepl.com/v2/translate?auth_key=${deeplKey}&text=${text}&source_lang=${lng}&target_lang=de`)
        .then(res => {
          if(!res.ok){
            console.log(res);
            setErrorCode('ERROR: ' + res.status)
          };
          return res.json()
        })
        .then(
          (result) => {
            console.log('answer from deepl', result);
            setIsLoaded(true);
            if (result.translations) {
              setTranslations(result.translations[0].text);
            }else if((result.message)){
              setTranslations(result.message);
            }
          },
          (error) => {
            console.log('error', error);
            setIsLoaded(true);
          }
        )
      }
  }, [text, lng, appState, deeplKey])

  if(text !== '' && appState !== 'show' && appState !== 'pre_translationBack'){
    if (!isLoaded) {
      return null //<p className="status">Übersetze zurück...</p>
    } else {
      return (<p className="translation_back">/<span className="lng">{lng}</span>{'/'} {translations} {errorCode}</p>)
    }
  } else {
      return null;
  }
}



export default TranslateBack;
