import React, {Fragment, useState, useEffect } from "react";
import "./videos.css";

function Videos(props) {
  const setInactive = props.setInactive;
  const server = props.server;
  const [videos, setVideos] = useState([]);
  const [videoFrame, setVideoFrame] = useState('');
  const [videoActive, setVideoActive] = useState(-1);


  useEffect(() => {
    console.log('server:' ,server);
    async function myloader() {
      try {
        console.log("lade_Videobeschreibungen");
        const response = await fetch(`${server}/php/loadvideos.php`, {
          method: 'get',
          headers: {
                   'Accept': 'application/json',
                   'Content-Type': 'application/json'
                   }
        });
        const videos = await response.json();
        for (var i = 0; i < videos.length; i++) {
          if( i === 0 ||  i === 1 || i === 2){videos[i]['back'] = './img/menue2.svg';}
          if( i === 3 || i === 4){videos[i]['back'] = './img/menue3.svg';}
          if( i === 5 || i === 6){videos[i]['back'] = './img/menue4.svg';}
        }
        console.log("antwort: ",videos);
        setVideos(videos);
      } catch (error) {
        console.log(error);
      }
    }
    myloader();
  },[server])

  const onClickVideoBtn = (index) =>{
    setInactive(Date.now());
    setVideoActive(index);
  }

  const buttons =
    <div className="buttons">
      <div className="line2"></div>
      {videos.map((video, index) =>
        <div key={video['video']} onClick={() => onClickVideoBtn(index)} className={'button '+((videoActive === index) ? 'active' : '')} style={{backgroundImage:`url(${video['back']})`}}><p dangerouslySetInnerHTML={{ __html: video['text'] }}/><div className='line'></div></div>
      )}
    </div>

    useEffect(() =>{
      let videoFrame = '';
      if(videoActive > -1){
        videoFrame =
        <div key={videos[videoActive]['video']} className='frame info_video fadeIn05'>
          <video className="video_player" autoPlay>
            <source src={'./_videos/'+videos[videoActive]['video']} type="video/mp4"/>
          </video>
        </div>;
      }
      setVideoFrame(videoFrame)
    },[videos, videoActive])


  return(
    <Fragment>
    <div className='info_videos'>
    {videoFrame}
    </div>
    <div className="selection">
      <div className="dot1"><div className="line1"></div></div>
      <div className="dot2">
        {buttons}
      </div>
    </div>
    </Fragment>
  )
}

export default Videos;
