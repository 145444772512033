import React, {Fragment, useState, useEffect } from 'react';
import Result from './result';
import Rotator from './rotator';
import Rotator2 from './rotator2';
import './gesicht.css';

function Gesicht(props) {
  const setInactive = props.setInactive;
  const [appState, setAppState] = useState('pre');
  const [mimik, setMimik] = useState([]);
  const [mimikTotal, setMimikTotal] = useState(0);
  const [active, setActive] = useState(-1);
  const server = props.server;
  const [content, setContent] = useState('');
  const [rating, setRating] = useState(-1);
  const [ratingLevel] = useState([﻿'Versuch es noch einmal','Könnte etwas besser sein','Etwas mehr Mut','Soweit okay','Etwas verhalten','Das geht noch besser','Einigermaßen erkenntlich ','Mittelprächtig','Etwas gewöhnlich','Geht in die richtige Richtung','Akzeptabel','Schöner Ansatz','Das sieht gut aus','Guter Durchschnitt','Nicht schlecht','Reife Leistung','Besser als die meisten','Trefflicher Ausdruck','Vortreffliche Leistung','Erstklassig','Großartig','Sehr gut','Hervorragend gelöst','Alle Achtung','Exzellent','Spitzenleistung','Wirklich gut gemacht','Phänomenal','Sehr, sehr, sehr gut','Besser geht es nicht mehr']);
  const [barStyle, setBarStyle] = useState({backgroundImage:`url(img/skala_balken.svg)`, backgroundSize: '100% 100%'});


  useEffect(() => {
    async function myloader() {
      try {
        console.log('lade_liste');
        const response = await fetch(`${server}/php/loadmimik.php`, {
          method: 'get',
          headers: {
                   'Accept': 'application/json',
                   'Content-Type': 'application/json'
                   }
        });
        const json = await response.json();
        console.log('antwort: ',json);
        setMimik(json);
      } catch (error) {
        console.log(error);
      }
    }
    myloader();
  }
  ,[server])


  useEffect(() => {
    console.log('appState ', appState);
    setInactive(Date.now());
    //console.log('appState ', appState, 'active :', active);
    if(appState === 'pre' || appState === 'result_end'){
      setContent(
        <div className="frame frage fadeIn05">
          <p>Wie gut kannst Du eine Emotion mit Deinem Gesicht ausdrücken?</p>
          <p>Du hast 5 Sekunden Zeit.</p>
        </div>
      )
    }else if(mimik.length > 0){
      setContent(
        <div className="frame frage fadeIn05">
          <p className='small'>Wie gut kannst Du eine Emotion mit Deinem Gesicht ausdrücken?</p>
          <img src={`${process.env.PUBLIC_URL}/_daten_mimik/${mimik[active].image}.png`} alt={mimik[active].image}/>
          <p className='ausdruck'>{mimik[active].text}</p>
        </div>
      );
    }else{
      setContent('');
    }
    if(appState === 'pre_running'){
      const timer = setTimeout(() => {
        setAppState('running');
        clearTimeout(timer);
      }, 1000);
      return () => clearTimeout(timer);;
    }
    if(appState === 'running'){
      const interval = setInterval(() => {
        setAppState('result');
        clearInterval(interval);
      }, 5000);
      return () => clearInterval(interval);
    }
    if(appState === 'result'){
      const rating_range = Math.floor(ratingLevel.length/3);
      const rating_min = Math.floor(((ratingLevel.length-rating_range)-(mimik.length/mimikTotal*(ratingLevel.length-rating_range)))*10)/10;
      const rating_max = rating_min+rating_range;
      let random = Math.floor(Math.random() * ratingLevel.length);
      var i = 1;
      for (i = 1; ((random < rating_min) || (random > rating_max)) ; i++){
        random = Math.floor(Math.random() * ratingLevel.length);
      }
      console.log('Versuche: ' + i + '; min: ' + rating_min + '; max: ' + rating_max + '; result: ' + random);
      setRating(random);
      const interval = setInterval(() => {
        setAppState('result_end1');
        clearInterval(interval);
      }, 3000);
      return () => clearInterval(interval);
    }
    if(appState === 'result_end1'){
      const interval = setInterval(() => {
        setAppState('result_end');
        clearInterval(interval);
      }, 1000);
      return () => clearInterval(interval);
    }

    if(appState === 'finish'){
      setContent(
        <div className="frame finish fadeIn05" style={{backgroundImage:`url(./_daten_mimik/mimik_ende.png)`}} >
        </div>
      )
    }


    if (mimikTotal === 0 ) {setMimikTotal(mimik.length)}
  }, [appState, active, mimik, mimikTotal, ratingLevel, setInactive])


  useEffect(() =>{
    const size = '100% '+(80*(rating+1)/ratingLevel.length+20)+'%';
    setBarStyle({backgroundImage:`url(img/skala_balken.svg)`, backgroundSize: size});
  },[rating, ratingLevel])

  const onClickStart = () =>{
    const random = Math.floor(Math.random() * Math.floor(mimik.length));
    setActive(random);
    setAppState('pre_running');
  }

  const onClickReStart = () =>{
    if(mimik.length >1){
      let tempArray = mimik;
      tempArray.splice(active, 1);
      setMimik(tempArray);
      const random = Math.floor(Math.random() * mimik.length);
      setActive(random);
      setAppState('pre_running');
    }else{
      let tempArray = mimik;
      tempArray.splice(active, 1);
      setMimik(tempArray);
      setAppState('finish');
    }
  }

  const start_btn = <div onClick={onClickStart} style={{backgroundImage:`url(img/start.svg)`}} className='button_round start_btn fadeIn05'><div className='line'></div></div>
  const timer_btn = <div className='button_round timer_btn'><div className='cover'><Rotator/></div><div className='cover2'><Rotator2/></div><div className='line'></div></div>

  return (
    <div className="gesicht">
    {/*
      <div className='console'>
      <p>
      mimik.map((element, index) =>
          (index === active) ? <span key={element.image} className='text active'>{element.text} </span>
          : <span key={element.image} className='text'>{element.text} </span>
        )
      </p>
      <p>
      alle Elemente: {mimikTotal+'; '}
      verbleibende Elemente: {mimik.length+'; '}
      rating: {rating+1}/{ratingLevel.length+'; '} </p>
      <p>
      {ratingLevel.map((element, index) =>
          (index === rating) ? <span key={element} className='text active'>{element} /</span>
          : <span key={element+index} className='text'>{element} /</span>
        )
      }</p>
      </div>
      */}
      {content}
      {(appState === 'pre') ? start_btn : ''}
      {(appState === 'running') ? timer_btn : ''}
      {(appState !== 'finish') ?
      <Fragment>
      <div className="frame spiegel fadeIn1">
      </div>
      <Result
        onClickReStart = {onClickReStart}
        appState = {appState}
        barStyle = {barStyle}
        comment = {ratingLevel[rating]}
        rating = {rating}
        ratingTotal = {ratingLevel.length}
      />
      </Fragment>
      :''}
    </div>
  );
}

export default Gesicht;
