import React, {useEffect, useState} from 'react';
//import SaveInfo from './save_info';



function SaveTranslation (props) {
  const appState = props.appState;
  const setAppState = props.setAppState;
  const toTranslate = props.toTranslate;
  const translation = props.translation;
  const translationBack = props.translationBack;
  const lng = props.lng;
  const server = props.server;
  const [saveAble, setSaveAble] = useState(true);



  useEffect(() => {
    async function save_now() {
      try {
        console.log("save_now");
        const response = await fetch(`${server}/php/saveinfo.php`, {
          method: 'POST',
          headers: {
                   'Accept': 'application/json',
                   'Content-Type': 'application/json'
                   },
          body: JSON.stringify({toTranslate : toTranslate, translation : translation, translationBack : translationBack, lng : lng})
        })
        const json = await response.text();
        console.log("antwort: ",json);
      } catch (error) {
        console.log(error);
      }
    }
    if(appState === 'save'){
      save_now();
      setAppState('wait');
      setSaveAble(false);
    }
  }, [server, appState, toTranslate, translation, translationBack, lng, setAppState])

useEffect(() =>{
  setSaveAble(true);
},[translationBack])


  const onClickSave = () => {
    setAppState('save');
    console.log('save');
  }



  const save_btn = <div onClick={onClickSave} style={{backgroundImage:`url(img/${(appState === 'save') ? 'plus_black' : 'plus_white'}.svg)`}} className={(appState === 'save') ? 'button_round like_btn active' : ' button_round like_btn'}><div className='line'></div><div className='line2'></div></div>
  const save_btn_empty = <div className={(appState === 'save') ? 'button_round like_btn active' : ' button_round like_btn'}><div className='line'></div><div className='line2'></div></div>

    return (
      <div>
        {(toTranslate.length > 0 && translation.length > 0 && translationBack.length > 0 && appState === 'wait' && saveAble) ? save_btn : ''}
        {(toTranslate.length > 0 && translation.length > 0 && translationBack.length > 0 && appState === 'wait' && ! saveAble) ? save_btn_empty : ''}
      </div>
    );

}

export default SaveTranslation
