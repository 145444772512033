import React, {useEffect, useState} from 'react';
import useSound from 'use-sound';

function LngButtons(props) {
  const [show, setShow] = useState (false);
  const lng = props.lng;
  const setLng = props.setLng;
  const toTranslate = props.toTranslate;
  const appState = props.appState;
  const server = props.server;
  const storedStringForTranslation = props.storedStringForTranslation;
  const setToTranslate = props.setToTranslate;
  const buttons = [
    {id:'en', active: false},
    {id:'es', active: false},
    {id:'fr', active: false},
    {id:'it', active: false},
    {id:'ja', active: false},
    {id:'nl', active: false},
    {id:'pl', active: false},
    {id:'pt', active: false},
    {id:'ru', active: false},
    {id:'zh', active: false}
  ];

  useEffect(() => {
    if(((toTranslate.length > 0) && (appState === 'wait' || appState === 'translation' || appState === 'translationBack' || appState === 'pre_translationBack')) || appState === 'show'){
      setShow(true);
    }else{
      setShow(false);
    }
  },[appState, toTranslate])

  const MyButton = (props) => {
    const [play] = useSound(server+'/_sprache/'+props.button.id+'.mp3');

    const onClickHandler = (id) => {
      play();
      setLng(id);
      if(appState === 'show'){setToTranslate(storedStringForTranslation)}
    }

    return<div onClick={()=>onClickHandler(props.button.id)} className={(lng === props.button.id)? 'lng_btn active' : 'lng_btn'}>{props.button.id}</div>
  };

  return (
    <div className={'lng_buttons ' + ((show)?'':'hide')}>
      {buttons.map(button =>
        <MyButton key = {button.id}
          button = {button}
        />
      )}
    </div>
  );
}

export default LngButtons;
