import React, {Fragment, useState, useEffect } from 'react';
import Menue from './components/menue';
import Content from './components/content';
import './App.css';

const App = () => {
  const deeplKey = process.env.REACT_APP_DEEPL_KEY;
  const server = process.env.REACT_APP_PHP_SERVER; //http://127.0.0.10 //https://dev-rd.de
  const resettime = 5*60*1000
  const [buttons, setButtons] = useState([
      {id:1, size:'big', active: false},
      {id:2, size:'big', active: false},
      {id:3, size:'big', active: false},
      {id:4, size:'big', active: false},
      {id:5, size:'big', active: false},
      {id:6, size:'small', active: false}
    ])
  const [contentId, setContentId] = useState(-1);
  const [inactive, setInactive] = useState(Date.now());

  useEffect(() => {
    const temp_buttons = [...buttons];
    //console.log('RESETED');
    const interval = setInterval(() => {
      let now = Date.now();
      let difference = now-inactive;
      //console.log('InactiveTime: ' + (Math.floor(difference/1000)) + 's');
      if(difference > resettime){
        setContentId(-1);
        for (var i = 0; i < temp_buttons.length; i++) {
          temp_buttons[i].active = false;
        }
        setButtons(temp_buttons);
        clearInterval(interval);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [buttons, inactive, resettime]);


  useEffect(() => {
    const temp_buttons = [...buttons];
    if(contentId === 0){
      const interval = setInterval(() => {
        setContentId(1);
        temp_buttons[0].active = true;
        setButtons(temp_buttons);
        clearInterval(interval);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [buttons, contentId]);

  const onClickHandler = (button) =>{
    const temp_buttons = [...buttons];
    const index = temp_buttons.indexOf(button);
    const contentId = temp_buttons[index].id;
    for (var i = 0; i < temp_buttons.length; i++) {
      temp_buttons[i].active = false;
    }
    temp_buttons[index].active = true;
    setButtons(temp_buttons);
    setContentId(contentId);
  }

    const onClickStart = (button) =>{
      setInactive(Date.now());
      setContentId(0);
    }

  return (
    <div className="App">
      {(contentId === -1 )?
        <video loop autoPlay onClick={() => {onClickStart()}} src={server+'/_videos/loop.mp4'} muted='muted' className="start_loop"/>
        :
        <Fragment>
          <div className="menue">
            <Menue
              buttons={buttons}
              onClick={onClickHandler}
            />
          </div>
          <div className="content">
            <Content
              setInactive={setInactive}
              id={contentId}
              deeplKey={deeplKey}
              server={server}
            />
          </div>
        </Fragment>
      }
      {(contentId === 0)?
        <audio autoPlay src='./_videos/introsound.mp3'/>
        :''}
    </div>

  );
}

export default App;
