import React, { useState, useEffect } from "react";
import Presentation from './presentation';
import PresentationDogs from './presentation_dogs';
import "./tiere.css";

function Tiere(props) {
  const setInactive = props.setInactive;
  const [appState, setAppState] = useState("pre");
  const [animals, setAnimals] = useState([]);
  const [dogs, setDogs] = useState([]);
  const [dogsLeft, setDogsLeft] = useState([]);
  const [animalsInLevels, setAnimalsInLevels] = useState([]);
  const [animalsInLevelsTotal, setAnimalsInLevelsTotal] = useState(0);
  //const [levelTotal, setLevelTotal] = useState(0);
  const [activeLevel, setActiveLevel] = useState(2);
  //const [selectedAnimal, setSelectedAnimal] = useState([]);
  const [shownAnimals, setShownAnimals] = useState([]);
  const [shownDogs, setShownDogs] = useState([]);
  const server = props.server;

  //loading on start
  useEffect(() => {
    async function myloader() {
      try {
        console.log("lade_TIERE");
        const response = await fetch(`${server}/php/loadanimals.php`, {
          method: 'get',
          headers: {
                   'Accept': 'application/json',
                   'Content-Type': 'application/json'
                   }
        });
        const json = await response.json();
        console.log("antwort: ",json);
        setAnimals(json);
      } catch (error) {
        console.log(error);
      }
    }
    async function myloader_dogs() {
      try {
        console.log("lade_Hunde");
        const response = await fetch(`${server}/php/loaddogs.php`, {
          method: 'get',
          headers: {
                   'Accept': 'application/json',
                   'Content-Type': 'application/json'
                   }
        });
        const json = await response.json();
        console.log("antwort: ",json);
        setDogs(json);
      } catch (error) {
        console.log(error);
      }
    }
    myloader();
    myloader_dogs();
  }
  ,[server])

  //init after loading
  useEffect(() => {
    let level_total = 0;
    for (var i = 0; i < animals.length; i++) {
      if(animals[i]['level'] > level_total){level_total = animals[i]['level']}
    }
    //setLevelTotal(parseInt(level_total));
    let temp = [];
    temp[0] = [];
    if(level_total > 0){
      for (i = 0; i < animals.length; i++) {
        if(!temp[animals[i]['level']]){temp[animals[i]['level']]=[]}
        temp[animals[i]['level']].push(animals[i]);
      }
    }
    setAnimalsInLevels(temp);
  },[animals])

  useEffect(() => {
    let temp = [];
    for (var i = 0; i < dogs.length; i++) {
      temp.push(dogs[i]);
    }
    setDogsLeft(temp);
  },[dogs])


  //check number of animals left
  useEffect(() => {
    console.log('Anpassung');
    let counter = 0;
    if(animalsInLevels.length>0){
      console.log('animals in levels:', animalsInLevels);
      animalsInLevels.map((level, index) => (
        counter += level.length
      ))
      setAnimalsInLevelsTotal(counter);
    }
  },[animalsInLevels])


  useEffect(() => {
    console.log("appState ", appState);
    setInactive(Date.now());
    if(appState === 'pre_running'){
      let error = false;
      let temp_animalsInLevels = [...animalsInLevels];
      let selected_animal = [];
      let shown_animals = [];
      let temp_dogs = [...dogsLeft];
      let shown_dogs = [];
      let task = 'animals';

      const next_Level_withElements = () =>{
        let selection_level = -1;
        switch(activeLevel){
          case 0:
          if(temp_animalsInLevels[activeLevel].length > 0) {selection_level = activeLevel;
          }else if(temp_animalsInLevels[activeLevel+1].length > 0){selection_level = activeLevel+1;
          }else if(temp_animalsInLevels[activeLevel+2].length > 0){selection_level = activeLevel+2;
          }else if(temp_animalsInLevels[activeLevel+3].length > 0){selection_level = activeLevel+3;
          }else if(temp_animalsInLevels[activeLevel+4].length > 0){selection_level = activeLevel+4;
          }else if(temp_animalsInLevels[activeLevel+5].length > 0){selection_level = activeLevel+5;
          }else if(temp_animalsInLevels[activeLevel+5].length > 0){selection_level = activeLevel+6;
          }
          break;
          case 1:
          if(temp_animalsInLevels[activeLevel].length > 0) {selection_level = activeLevel;
          }else if(temp_animalsInLevels[activeLevel+1].length > 0){selection_level = activeLevel+1;
          }else if(temp_animalsInLevels[activeLevel+2].length > 0){selection_level = activeLevel+2;
          }else if(temp_animalsInLevels[activeLevel+3].length > 0){selection_level = activeLevel+3;
          }else if(temp_animalsInLevels[activeLevel+4].length > 0){selection_level = activeLevel+4;
          }else if(temp_animalsInLevels[activeLevel+5].length > 0){selection_level = activeLevel+5;
          }
          break;
          case 2:
          if(temp_animalsInLevels[activeLevel].length > 0) {selection_level = activeLevel;
          }else if(temp_animalsInLevels[activeLevel+1].length > 0){selection_level = activeLevel+1;
          }else if(temp_animalsInLevels[activeLevel-1].length > 0){selection_level = activeLevel-1;
          }else if(temp_animalsInLevels[activeLevel+2].length > 0){selection_level = activeLevel+2;
          }else if(temp_animalsInLevels[activeLevel+3].length > 0){selection_level = activeLevel+3;
          }else if(temp_animalsInLevels[activeLevel+4].length > 0){selection_level = activeLevel+4;
          }else if(temp_animalsInLevels[activeLevel+5].length > 0){selection_level = activeLevel+5;
          }
          break;
          case 3:
          if(temp_animalsInLevels[activeLevel].length > 0) {selection_level = activeLevel;
          }else if(temp_animalsInLevels[activeLevel+1].length > 0){selection_level = activeLevel+1;
          }else if(temp_animalsInLevels[activeLevel-1].length > 0){selection_level = activeLevel-1;
          }else if(temp_animalsInLevels[activeLevel+2].length > 0){selection_level = activeLevel+2;
          }else if(temp_animalsInLevels[activeLevel-2].length > 0){selection_level = activeLevel-2;
          }else if(temp_animalsInLevels[activeLevel+3].length > 0){selection_level = activeLevel+3;
          }
          break;
          case 4:
          if(temp_animalsInLevels[activeLevel].length > 0) {selection_level = activeLevel;
          }else if(temp_animalsInLevels[activeLevel+1].length > 0){selection_level = activeLevel+1;
          }else if(temp_animalsInLevels[activeLevel-1].length > 0){selection_level = activeLevel-1;
          }else if(temp_animalsInLevels[activeLevel+2].length > 0){selection_level = activeLevel+2;
          }else if(temp_animalsInLevels[activeLevel-2].length > 0){selection_level = activeLevel-2;
          }else if(temp_animalsInLevels[activeLevel-3].length > 0){selection_level = activeLevel-3;
          }
          break;
          case 5:
          if(temp_animalsInLevels[activeLevel].length > 0) {selection_level = activeLevel;
          }else if(temp_animalsInLevels[activeLevel+1].length > 0){selection_level = activeLevel+1;
          }else if(temp_animalsInLevels[activeLevel-1].length > 0){selection_level = activeLevel-1;
          }else if(temp_animalsInLevels[activeLevel-2].length > 0){selection_level = activeLevel-2;
          }else if(temp_animalsInLevels[activeLevel-3].length > 0){selection_level = activeLevel-3;
          }else if(temp_animalsInLevels[activeLevel-4].length > 0){selection_level = activeLevel-4;
          }
          break;
          case 6:
          if(temp_animalsInLevels[activeLevel].length > 0) {selection_level = activeLevel;
          }else if(temp_animalsInLevels[activeLevel-1].length > 0){selection_level = activeLevel-1;
          }else if(temp_animalsInLevels[activeLevel-2].length > 0){selection_level = activeLevel-2;
          }else if(temp_animalsInLevels[activeLevel-3].length > 0){selection_level = activeLevel-3;
          }else if(temp_animalsInLevels[activeLevel-5].length > 0){selection_level = activeLevel-5;
          }
          break;
          default:
          selection_level = -1;
        }
        return(selection_level);
      }

      //hund oder tiere
      if((activeLevel === 5 || activeLevel === 6) && dogsLeft.length > 2){
        if((Math.random()*3) > 2){
          task = 'dogs';
        }
      }

      console.log('Task: ', task);
      //tiere aussuchen

      if(task === 'animals'){
        //ein Tier, welches vorgespielt wird
        if (animalsInLevelsTotal > 5){

          let selection_level = -1;
          selection_level = next_Level_withElements();
          let selection = Math.floor(Math.random()*temp_animalsInLevels[selection_level].length);

          selected_animal = temp_animalsInLevels[selection_level][selection];
          temp_animalsInLevels[selection_level].splice(selection, 1);

          //4 Tiere aus aktuellem Level oder 1 drüber eins drunter
          for (var i = 0; i < 4; i++) {
            let selection_level = -1;
            selection_level = next_Level_withElements();
            if(selection_level > 0){
              let selection = Math.floor(Math.random()*temp_animalsInLevels[selection_level].length);
              shown_animals[i] = temp_animalsInLevels[selection_level][selection];
              temp_animalsInLevels[selection_level].splice(selection, 1);
            }else{
              error = true;
            }
          }
          shown_animals[4]=selected_animal;
          setShownAnimals(shown_animals);
          if(shown_animals.length > 0){
            for (var a = 0; a < 4; a++) {
              if(shown_animals[a]){
                let back_level = shown_animals[a]['level'];
                let back_index = temp_animalsInLevels[back_level].length;
                temp_animalsInLevels[back_level][back_index] = shown_animals[a];
              }else{error = true;}
            }
          }else{error = true;}
          setAnimalsInLevels(temp_animalsInLevels);
        }else{
          error = true;
        }
        if(error){setAppState('end')}
        else{setAppState('running')}
      }else{
        for (var j = 0; j < 3; j++) {
          let selection = Math.floor(Math.random()*temp_dogs.length);
          shown_dogs[j] = temp_dogs[selection];
          temp_dogs.splice(selection, 1);
        }
        for (var b = 0; b < 2; b++) {
          if(shown_dogs[b]){
            let back_index = temp_dogs.length;
            temp_dogs[back_index] = shown_dogs[b];
          }
        }
        setDogsLeft(temp_dogs);
        setShownDogs(shown_dogs);
        setAppState('running_dogs');
      }
    }
  }, [appState, activeLevel, animalsInLevels, animalsInLevelsTotal, setInactive, dogsLeft])

  const onClickStart = () =>{
    setAppState("pre_running");
  }

/*
  const ShowAnimalsInLevels = () => {
    let my_return = '';
    for (var i = 0; i < animalsInLevels.length; i++) {
      if(animalsInLevels[i]){
          my_return += 'Level'+i+': '
        for (var j = 0; j < animalsInLevels[i].length; j++){
          my_return += animalsInLevels[i][j].image + ' ';
        }
      }
    }
    return(<div className='level'>{my_return}</div>);
  }
*/

  const start_btn = <div onClick={onClickStart} style={{backgroundImage:`url(img/start.svg)`}} className="button_round start_btn"><div className="line"></div></div>
  const empty_btn = <div className="button_round empty_btn"><div className="line"></div></div>

  return (
    <div className="tiere">
    {/*
      <div className="console">
      <ShowAnimalsInLevels/>
      <p>
        verbleibende Elemente: {animalsInLevelsTotal} / {animals.length+"; "}
        Level: {activeLevel}<br/>
        Auswahl {(shownAnimals.length > 0)? shownAnimals[4].image : ''}<br/>
        Anzeige {shownAnimals.map((animal) => (animal.image+' '))}
      </p>
      </div>
    */}
      {(appState === 'end')?
        <div className="frame finish fadeIn05" style={{backgroundImage:`url(./_daten_tiere/tier_ende.png)`}} >
        </div>
        :
        <div className="frame frage fadeIn05">
          {empty_btn}
          {(appState === "result" || appState === "result_dogs" ) ?
            <div className="nochmal">Neues Tier anhören</div>
            :''}
          {(appState === "pre" || appState === "running" || appState === "pre_running" ) ?
            <div>Welches Tier hörst Du
              <div className='fragezeichen'>?</div>
            </div>
            :''}
            {(appState === "running_dogs" ) ?
              <div>Welche Emotion drückt das Hundebellen aus?
                <div className='fragezeichen'>?</div>
              </div>
              :''}
          {(appState === "pre" || appState === "result" || appState === "result_dogs") ? start_btn : ""}
        </div>
      }

      <Presentation
        appState = {appState}
        setAppState = {setAppState}
        shownAnimals = {shownAnimals}
        activeLevel = {activeLevel}
        setActiveLevel = {setActiveLevel}
        onClickStart = {onClickStart}
        animalsInLevelsTotal = {animalsInLevelsTotal}
      />
      <PresentationDogs
        appState = {appState}
        setAppState = {setAppState}
        shownDogs = {shownDogs}
        activeLevel = {activeLevel}
        setActiveLevel = {setActiveLevel}
        onClickStart = {onClickStart}
      />
    </div>

  );
}

export default Tiere;
