import React, {Fragment, useEffect, useState} from 'react'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'


function InputVoice (props) {
  const { transcript, resetTranscript, listening } = useSpeechRecognition();
  const appState = props.appState;
  const [transcriptTemp, setTranscriptTemp] = useState('');

  const onClickMic = () => {
    if(listening){
      setTranscriptTemp('');
      resetTranscript();
      SpeechRecognition.startListening();
    }else{
      if((transcriptTemp.length + transcript.length) < 300){
        props.setAppState('input');
        SpeechRecognition.startListening();
      }
    }
  }
  const mic_btn = <div onClick={onClickMic} style={{backgroundImage:`url(img/${listening ? 'mic_black' : 'mic_white'}.svg)`}} className={listening ? 'button_round mic_btn active' : ' button_round mic_btn'}><div className='line'></div></div>

  useEffect(()=> {
    if (props.appState !== 'input' && listening ){
        SpeechRecognition.stopListening();
        setTranscriptTemp('');
        resetTranscript();
    }
    if (props.appState === 'input'){
      if(listening) console.log('listening');
      if(!listening) console.log('!listening');


      if((transcript.length > 270 || (transcriptTemp.length + transcript.length) > 270) && listening) {
        console.log('zu lang');
        SpeechRecognition.stopListening();
        const lng_list = [...props.lng_list];
        const selection = Math.floor(Math.random()*lng_list.length)
        props.setToTranslate(transcriptTemp + ' ' + transcript);
        props.setLng(lng_list[selection]);
        props.setAppState('translation');
        resetTranscript();
        setTranscriptTemp('');

        //start translation
        /*
        props.setAppState('translation');
        props.setToTranslate(transcript);
        resetTranscript();
        */
      };

      if(!listening && transcript.length > 0 && (transcriptTemp.length + transcript.length) < 270 ){
        console.log('setze temp');
        setTranscriptTemp(transcriptTemp + ' ' + transcript);
        resetTranscript();
        if((transcriptTemp.length + transcript.length) < 270){
          SpeechRecognition.startListening();
        }
        //console.log('ready');
      };
      if(!listening && transcript.length < 1 ){
        if(transcriptTemp.length < 270){
          SpeechRecognition.startListening();
        }
      };
    }
  },[props, listening, transcript, transcriptTemp, resetTranscript]);

  const onClickOK = () => {
    const lng_list = [...props.lng_list];
    const selection = Math.floor(Math.random()*lng_list.length)
    props.setLng(lng_list[selection]);
    props.setAppState('translation');
    props.setToTranslate(transcriptTemp);
    resetTranscript();
    setTranscriptTemp('');
  }

  const ok_btn = <div onClick={onClickOK} style={{backgroundImage:`url(img/ok.svg)`}} className='ok_btn'><span className='ok_btn_text'>Übersetzung und<br/>Rückübersetzung starten</span></div>

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return (<div>Der verwendete Browser unterstützt keine Stimmerkennung...</div>)
  }



  return (
    <Fragment>
      {mic_btn}
      {(appState === 'input' && transcriptTemp.length > 2) ? ok_btn:''}
      {(appState === 'input') ? <p>{transcriptTemp + ' ' + transcript}</p> : ''}
    </Fragment>
  )
}

export default InputVoice
