import React, {Fragment, useState, useEffect} from 'react';
import Bar from './bar';
import './gesicht.css';

function Result(props) {
  const appState = props.appState;
  const onClickReStart = props.onClickReStart;
  const comment = props.comment;
  const rating = props.rating;
  const ratingTotal = props.ratingTotal;
  const [key1, setKey1] = useState(0.8);
  const [key2, setKey2] = useState(1);
  const [key3, setKey3] = useState(0.9);

  const restart_btn = <div onClick={onClickReStart} style={{backgroundImage:`url(img/start.svg)`}} className='button_round start_btn'><div className='line'></div></div>

  useEffect(() =>{
    if(appState === 'result'){
      setKey1(Math.random()*0.8);
      setKey2(Math.random()*1);
      setKey3(Math.random()*0.9);
    }
  },[appState])

  return (
    <Fragment>
      {(appState === 'result' || appState === 'result_end1' || appState === 'result_end') ?
        <div className="frame result">
          <img className='zahnrad' src={(appState === 'result')?'./img/wheel_animated.svg':'./img/wheel.svg'} alt='zahnrad'/>
          <img className='skala' src={'./img/skala.svg'} alt='skala'/>
          <div className='skala_balken'>
            <Bar
              height = {628*(rating+1)/ratingTotal+50+'px'}
              key1 = {key1}
              key2 = {key2}
              key3 = {key3}
            />
          </div>
          <div className='scala_text'>
          {(appState === 'result_end1' || appState === 'result_end') ? <p className='comment'>{comment}</p> : ''}
          </div>
        </div>
      : ''}
      {(appState === 'result_end') ? <Fragment>{restart_btn} </Fragment> : ''}
      </Fragment>
  );
}

export default Result;
