import React, {Fragment, useState, useEffect, useRef } from "react";


function PresentationDogs(props) {
  const appState = props.appState;
  const setAppState = props.setAppState;
  const shownDogs = props.shownDogs;
  const activeLevel = props.activeLevel;
  const setActiveLevel = props.setActiveLevel;
  const onClickStart = props.onClickStart;
  const [tryCount, setTryCount] = useState(0);
  const [presentedDogs, setPresentedDogs] = useState([]);
  const [sound, setSound] = useState('');
  const [playing, setPlaying] = useState(false);
  const player = useRef(null);

  useEffect(() => {
    let temp_array = [];
    let error = false;
    for (var i = 0; i < shownDogs.length; i++) {
      if(!shownDogs[i]){error = true}
    }
    if(!error){
      console.log('präsentation: ',shownDogs);
      for (i = 0; i < shownDogs.length; i++) {
        temp_array[i] = shownDogs[i];
        temp_array[i]['shown'] = true;
        if (i===2){temp_array[i]['active'] = true;
        }else{temp_array[i]['active'] = false;}
      }
      for(i = temp_array.length - 1; i > 0; i--){
        const j = Math.floor(Math.random() * i)
        const temp = temp_array[i]
        temp_array[i] = temp_array[j]
        temp_array[j] = temp
      }
      setPresentedDogs([...temp_array]);
    }else {console.log('error IN presentation');}
  },[shownDogs])

  useEffect(() => {
    console.log('update');
    for (var i = 0; i < presentedDogs.length; i++) {
      if(presentedDogs[i]['active']){setSound(`${process.env.PUBLIC_URL}/_daten_tiere/${presentedDogs[i].image}.mp3`)}
    }
  },[presentedDogs])

  useEffect(() => {
    let my_player;
    if (player.current){
      my_player = player.current;
      my_player.play();
      console.log('playing');
      setPlaying(true);
      my_player.addEventListener('ended', () => {setPlaying(false)});
    }
    return () => {
      if(my_player){
        my_player.removeEventListener('ended', () => {setPlaying(false)});
      }
    }
  },[sound])

  const onClickReplay = () => {if (player.current){player.current.play();setPlaying(true);}}

  const onClickTest = (active, index) => {
    if(active){
      setAppState('result_dogs');
      let temp_level = activeLevel;
      if (tryCount < 1) {temp_level+=1};
      if (tryCount > 1) {temp_level-=1};
      if (tryCount > 2) {temp_level-=2};
      if (tryCount > 3) {temp_level-=3};
      if (temp_level < 1) {temp_level=1};
      if (temp_level > 6) {temp_level=6};
      setActiveLevel(temp_level);
      setTryCount(0);
    }
    else{
      let temp_array = presentedDogs;
      console.log('shown? ',temp_array[index]['shown']);
      temp_array[index]['shown'] = false;
      setPresentedDogs([...temp_array]);
      setTryCount(tryCount+1);
    }
  }

    const onClickQuit = () => {
      setAppState('result_dogs');
      let temp_level = activeLevel;
      temp_level-=3;
      if (temp_level < 1) {temp_level=1};
      setActiveLevel(temp_level);
      setTryCount(0);
      onClickStart();
    }

  useEffect(()=>{
    console.log('Versuche: ',tryCount);
  },[tryCount])

  return (
    <div className='presentation_dogs'>
    {(appState === 'running_dogs')?
    <Fragment>
    {presentedDogs.map((dog, index) => (
      <div key={index} className={'fadeIn05 container '+((dog.shown)?'shown':'hidden')}>
        <div  className={'frame box box'+index} style={{backgroundImage:`url(${process.env.PUBLIC_URL}/_daten_tiere/${dog.image}e.png)`}}></div>
        <div  onClick={()=>onClickTest(dog.active, index)} className='button_round ask_btn' style={{backgroundImage:`url(img/ask.svg)`}}><div className={'line line'+index}></div></div>
      </div>
    ))}
    <div className='no_idea fadeIn1'>
      <div  className='frame box box_no_idea'>Keine Ahnung</div>
      <div  onClick={onClickQuit} className='button_round quit_btn' style={{backgroundImage:`url(img/quit.svg)`}}><div className='line line_no_idea'></div></div>
    </div>
    </Fragment>
    :''}
    {(appState === 'running_dogs' || appState === 'result_dogs' || appState === 'result_end')?
      <Fragment>
        {(sound !== '')?<audio id='mysound' ref={player} src={sound}/>:''}
      </Fragment>
    :''}
    {(appState === 'result_dogs' || appState === 'result_end')?
    <Fragment>
      <div className='frame fadeIn05 result_img' style={{backgroundImage:`url(${process.env.PUBLIC_URL}/_daten_tiere/${shownDogs[2].image}.png)`}}></div>
      <div className='frame fadeIn05 result_text' dangerouslySetInnerHTML={{ __html: shownDogs[2].text }}/>
    </Fragment>
    :''}
    {(appState === 'running_dogs') && (!playing) ? <div onClick={onClickReplay} className='button_round replay_btn' style={{backgroundImage:`url(img/play.svg)`}}></div> : ''}
    {(appState === 'result_dogs' || appState === 'result_end') && (!playing) ? <div onClick={onClickReplay} className='button_round replay_btn result' style={{backgroundImage:`url(img/play.svg)`}}><div className="line"></div></div> : ''}
    </div>
  );
}

export default PresentationDogs;
